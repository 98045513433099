.react-calendar {
    width: 250px;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 34px;
  }
  .react-calendar__navigation button {
    min-width: 34px;
    background: none;
  }
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:hover,
  .react-calendar__navigation button:focus {
    background: #0072ff;
    cursor: pointer;
    color: #fff;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__days__day {
    color: #212121;
    font-weight: bold;
    font-size: 0.75em;
  }
  
  .react-calendar__navigation__label__labelText--from {
    color: #212121;
    text-transform: capitalize;
  }
  .react-calendar__navigation__arrow {
    color: #212121;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #dadbdd;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #dadbdd;
  }
  
  .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;
    padding-bottom: 2px;
    background: none;
    text-align: center;
    height: 34px;
  }
  .react-calendar__tile:disabled {
    background-color: none;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    border-radius: 50%;
    background: #76baff;
    color: #fff;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #76baff !important;
    color: #fff;
    border-radius: 50%;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: rgba(128, 128, 128, 0.4);
    border-radius: 50%;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #7ba9ff;
  }
  
  .react-calendar__tile--now {
    background: #0072ff !important;
    color: #fff;
    border-radius: 50%;
  }
  
  .react-calendar__navigation__label__labelText {
    color: #212121;
    font-weight: 500;
    font-size: 16px;
    background: none !important;
  }
  
  .react-calendar__navigation__label {
    background: none !important;
  }
  
  .react-calendar__navigation__arrow {
    color: #212121;
    font-weight: 700;
    font-size: 1.7rem;
    cursor: pointer;
    padding-bottom: 9px;
    border-radius: 50%;
  }
  
  .react-calendar__navigation__arrow:disabled {
    color: #757575;
  }
  